import React from "react"
import { Link } from "gatsby"
import CookieConsent from 'react-cookie-consent'

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <button
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </button>
          <nav id="swup" class="site-head-left">
            <ul className="nav">
              <li className="nav-home">
              <div>
                <Link activeClassName="nav-current" to={`/`} role="menuitem">Home</Link>
              </div>
              </li>
              <li className="nav-about">
                <Link activeClassName="nav-current" to={`/about`} role="menuitem">About</Link>
              </li>
              <li className="nav-mail">
                <a href="mailto:georg@gilstein.de" role="menuitem">Contact</a>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.instagram.com/schnorg"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background:'black', fontSize: "20px", fontFamily:'Space Mono', color:'white', verticalAlign:'middle' }}
        >
          <span style={{ fontSize: "18px" }}>🍪🍪🍪 Cookies! 🍪🍪🍪</span><br />
          I'm using them to optimize user experience and to celebrate the numbers.
          {" "}<Link to={"/datenschutz"}>More info about Data Privacy</Link>
        </CookieConsent>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> <br></br>  
        {" "}<Link to={'/datenschutz'}>Data Privacy</Link>&nbsp;&mdash;&nbsp; 
        <Link to={'/impressum'}>Legal notice</Link><br />
         Made with ❤ (and a lot of Cola) by me.
      </footer>
    </div>
  )
}

export default Layout
